import React from "react"
import Layout from "../layouts/auto"
import Link from "../components/link"
import Seo from "../components/seo"
import SimplePage from "./simple-page"
import BlockContent from "../components/block-content"
import { FormattedMessage } from "react-intl"

const PostTemplate = ({ pageContext }) => {
    const { data, locale } = pageContext

    let published

    if (locale === "en")
        published = data.publishedEn
    else
        published = data.publishedDe

    return (
        <Layout locale={locale.code}>
            <Seo title={data.title[locale.code]} description={data.excerpt[locale.code]} image={data.mainImage ? data.mainImage.asset.file.publicURL : null}/>
            <SimplePage title={data.title[locale.code]} image={data.mainImage ? data.mainImage.asset.gatsbyImageData : null} subline={published}>
                <div className="markdown">
                    {locale.code in data._rawBody &&
                        <BlockContent blocks={data._rawBody[locale.code] || []} />
                    }
                    {data.link &&
                        <Link to={data.link} className="accessory">
                            <FormattedMessage id="global.explore" />
                        </Link>
                    }
                </div>
            </SimplePage>
        </Layout>
    )
}

export default PostTemplate
